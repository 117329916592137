import { Component, ViewChild, ElementRef } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { MESSAGE_CODE } from "src/app/shared/constant/message-constant";
import { TOAST } from "src/app/shared/constant/primeng-constants";
import { LoadingState } from "src/app/shared/html-parts/loading/loading-state";
import {
  MessageData,
  ToastMessageData,
} from "src/app/shared/html-parts/message-common/message-data";
import { CommonService } from "src/app/shared/service/common.service";
import { DbOperationService } from "src/app/shared/service/db-operation.service";
import {
  API_URL_CHAT_STATUS,
  API_URL_GENERATE_CHAT_LIST,
  API_URL_MATCH_CHAT_DETAIL,
  API_URL_UPDATE_MATCH_STATUS,
} from "manager/http-constants";
import { API_RESPONSE } from "src/app/shared/constant/api-constant";
import {
  CHATSTATUSES,
  CONSTANT,
  LOADING_KEY,
  RECRUITMENT_TYPE,
  ROLES,
} from "src/app/shared/constant/constant";
import { PlatformComponent } from "../platform.component";
import { ApprovalDialogComponent } from "../common/approval-dialog/approval-dialog.component";
import { LoginService } from "../../../shared/service/login.service";
import moment from "moment";
import { Subscription } from "rxjs";
import { ConfirmRejectMatchDialogComponent } from "../common/confirm-reject-match-dialog/confirm-reject-match-dialog.component";
import { MenuItem, MessageService } from "primeng/api";
import { NO_IMAGE } from "manager/file-contstants";
import { TEST_DOMAIN } from "manager/environment";

@Component({
  selector: "app-c601",
  templateUrl: "./c601.component.html",
  styleUrls: ["./c601.component.scss", "../platform.component.scss"],
})

/**
 * 提案詳細
 */
export class C601Component extends PlatformComponent {
  /** 共通部品_承認時ダイアログ */
  @ViewChild(ApprovalDialogComponent)
  approvalDialogComponent: ApprovalDialogComponent;

  @ViewChild("chatList") chatList: ElementRef<HTMLDivElement>;
  @ViewChild("listPanelChat") listPanelChat: ElementRef<HTMLDivElement>;

  @ViewChild("messageItem") messageItem: ElementRef<HTMLDivElement>;
  /** 削除ダイアログ */
  @ViewChild(ConfirmRejectMatchDialogComponent)
  confirmRejectMatchDialogComponent: ConfirmRejectMatchDialogComponent;

  isShowTimeDialog: boolean = false;
  currentChat: Subscription;

  // member_id of user login
  member_id: any = this.getLoginMemberId();
  listStatus: any = {};

  listStatusChange: Object[] = [CHATSTATUSES.ADJUSTMENT, CHATSTATUSES.END];
  // // 画面.チャット入力フォーム
  c601ChatInputForm: FormGroup = this.formBuilder.group({
    start_implementation_date: this.formBuilder.control(CONSTANT.EMPTY_STRING),
    end_implementation_date: this.formBuilder.control(CONSTANT.EMPTY_STRING),
    status: this.formBuilder.control(CONSTANT.EMPTY_STRING),
  });

  // 提案IDを格納
  suggestId: string = this.route.snapshot.params.suggest_id;

  // 提案詳細格納先
  proposaDetail: any;
  messageList: any[] = [];
  chatContent: string;
  listChatCommon: any[] = [];
  listChatCommonNew: any[] = [];
  listChatDetail: any[] = [];
  listChatDetailNew: any[] = [];
  matchId: any = null;
  listMatchChatDetail: any;
  sendingStt: boolean;
  currentRoute: Subscription;
  classId: string = this.loginUser.class_id;

  items: MenuItem[] = [];
  isEditMsg: boolean = false;
  numMsgEdit: number;
  chatContentEdit: string;
  intervalListMsg: ReturnType<typeof setTimeout>;
  listCommonNew: any[];
  intervalListHistoryMsg: ReturnType<typeof setTimeout>;

  isM4: any = false;
  agenda_id:any = null;

  listChatCommonCount: any = 0;

  supporterImageMap = new Map<string, string>();

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public commonService: CommonService,
    public dbOperationService: DbOperationService,
    public loadingState: LoadingState,
    public loginService: LoginService,
    private formBuilder: FormBuilder,
    private messageData: MessageData,
    private messageService: MessageService,
  ) {
    super(route, commonService, dbOperationService, loadingState);
  }

  ngOnInit(): void {
    this.matchId = null;
    this.agenda_id = localStorage.getItem('agenda_id')
    this.isM4 = localStorage.getItem('isM4')

    this.init2()
  }

  init2() {
    let hasMatchId = false;
    this.currentRoute = this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.matchId = this.route.snapshot.paramMap.get("id");
        if (this.matchId) {
          hasMatchId = true;
          this._initChatChannel(this.route.snapshot.paramMap.get("id"));
        } else {
          if(this.intervalListMsg){
            clearInterval(this.intervalListMsg);
            this.intervalListMsg = null;
            this.loadingState.loadForcedEnd();
            this._loadData();
          }
        }
      }
    });
    if (!hasMatchId) {
      this.matchId = this.route.snapshot.paramMap.get("id");
    }
    this._loadData();
  }

  onClickMenu(i, message) {
    this.items = [];
    this.items.push({
      label: "編集",
      icon: "pi pi-pencil",
      command: () => this.updateMsg(i, message),
    });

    this.items.push({
      label: "削除",
      icon: "pi pi-trash",
      command: () => this.deleteMsg(i, message),
    });

    return this.items;
  }
  public init() {
    this._loadData();
  }

  private async _loadData() {
    await this._initChat();
  }

  private _loadChatHistory(isload) {
    return new Promise<any>((resolve) => {
      // 画面ロードフラグをON(ロード中状態)
      if (isload) {
        this.loadingState.loadStart(API_URL_GENERATE_CHAT_LIST);
      }
      const table = "chat";
      // TODO: load chat history
      this.dbOperationService
        .getForkJoinDataNoTemplate(table, this.isM4 == 'true' ? API_URL_GENERATE_CHAT_LIST + '?agenda_id=' + this.agenda_id + '&' : API_URL_GENERATE_CHAT_LIST)
        .subscribe(async (response: any) => {
          if (!this.commonService.checkNoneResponse(response)) {
            // list chat
            this.listChatCommon = response.body;
            this.listChatCommonCount = Object.keys(this.listChatCommon).length;
            if (this.loginUser?.class_id == "2"){
              const listIntegrationId = [...new Set(this.listChatCommon.map(chat => chat.integration_id))];
              await this.initialSupporterImage(listIntegrationId);
            }
          } else {
            this.listChatCommon = new Array();
          }
          // 画面ロードフラグをOFF(ロード終了)
          if (isload) {
            this.loadingState.loadSleepEnd(0.3, API_URL_GENERATE_CHAT_LIST);
          }
          resolve(null);
        });
    });
  }

  /**
   * Validates supporter images for a list of integration IDs.
   *
   * This asynchronous function maps over an array of integration IDs, checking the validity of each corresponding image URL
   * by calling the `isImageUrl` method. It returns a promise that resolves to an array of objects, each containing the 
   * integration ID and a boolean indicating whether the image is valid.
   *
   * @param {string[]} listIntegrationId - An array of integration IDs to check for valid supporter images.
   * @returns {Promise<Array<{ integrationId: string, isValid: boolean }>>} A promise that resolves to an array of objects,
   *          each containing the integration ID and a boolean indicating if the image is valid.
   */
  async initialSupporterImage(listIntegrationId: string[]) {
    const promises = listIntegrationId.map(async (integrationId) => ({
      integrationId,
      isValid: await this.isImageUrl(integrationId)
    }));

    return Promise.all(promises);
  }

  /**
   * Checks if the provided integration ID corresponds to a valid image URL.
   *
   * This method attempts to load an image from a constructed URL based on the integration ID.
   * It resolves to true if the image loads successfully, or false if it fails to load or times out.
   *
   * @param {string} integrationId - The unique identifier for the integration, used to construct the image URL.
   * @returns {Promise<boolean>} A promise that resolves to true if the image exists, false otherwise.
   */
  async isImageUrl(integrationId: string): Promise<boolean> {
    let imageUrl;
    if (window.location.host == TEST_DOMAIN){
      imageUrl = `https://tokyo-ss-dev-file.s3.ap-northeast-1.amazonaws.com/common/avatar/${integrationId}.png`;
    }else{
      imageUrl = `https://stg-mypage.sumasapo.metro.tokyo.lg.jp/static/img/profile/supporter/${integrationId}.png`;
    }

    return new Promise<boolean>((resolve) => {
      const img = new Image();
      let isTimeout = false;

      const timer = setTimeout(() => {
        isTimeout = true;
        this.supporterImageMap.set(integrationId, NO_IMAGE);
        resolve(false);
        img.src = ''; // Cancel the image loading
      }, 5000);

      img.onload = () => {
        if (!isTimeout) {
          clearTimeout(timer);
          this.supporterImageMap.set(integrationId, imageUrl);
          resolve(true);
        }
      };

      img.onerror = () => {
        if (!isTimeout) {
          clearTimeout(timer);
          this.supporterImageMap.set(integrationId, NO_IMAGE);
          resolve(false);
        }
      };

      img.src = imageUrl;
    });
  }

  private _loadChatHistoryNew() {
    return new Promise<any>((resolve) => {
      // 画面ロードフラグをON(ロード中状態)

      const table = "chat";
      // TODO: load chat history
      this.dbOperationService
        .getForkJoinDataNoTemplateLastestRuncount(
          table,
          this.isM4 == 'true' ? API_URL_GENERATE_CHAT_LIST + '?agenda_id=' + this.agenda_id + '&' : API_URL_GENERATE_CHAT_LIST
        )
        .subscribe((response: any) => {
          if (!this.commonService.checkNoneResponse(response)) {
            // list chat
            this.listChatCommonNew = response.body;
            this.listChatCommon.map((chat, index) => {
              this.listChatCommonNew.map((chatNew, indexNew) => {
                if (chatNew.match_id == chat.match_id) {
                  if (
                    Number(this.listChatCommon[index].count_unread) <
                    Number(this.listChatCommonNew[indexNew].count_unread)
                  ) {
                    this.listChatCommon.splice(index, 1);
                    this.listChatCommon.unshift(chatNew);
                  } else if (
                    Number(this.listChatCommon[index].count_unread) >
                    Number(this.listChatCommonNew[indexNew].count_unread)
                  ) {
                    this.listChatCommon[index].count_unread =
                      this.listChatCommonNew[indexNew].count_unread;
                  }
                  this.listChatCommon[index].last_chat_datetime = this.listChatCommonNew[indexNew].last_chat_datetime
                }
              });
            });
          } else {
            this.listChatCommonNew = new Array();
          }
          // 画面ロードフラグをOFF(ロード終了)

          resolve(null);
        });
    });
  }

  private _unsubscribeChat() {
    if (this.currentChat) {
      this.currentChat.unsubscribe();
      this.currentChat = null;
    }
  }

  private _initChatChannel(matchId) {
    if(!this.intervalListMsg){
      this.intervalListMsg = setInterval(async () => {
        if (!this.matchId) {
          clearInterval(this.intervalListMsg);
        } else {
          await this.intervalListChat();
        }
      }, 5000);
    };
    this.intervalListChat();
  }

  private async intervalListChat() {
    await this.initGetMatchChatDetailNew(this.matchId);
    await this.initGetListChatNew(this.matchId);
    this.listStatus[CHATSTATUSES.REQUEST_PROPOSAL.code] = [
      CHATSTATUSES.REQUEST_PROPOSAL,
      CHATSTATUSES.ADJUSTMENT,
      CHATSTATUSES.DECISION,
      CHATSTATUSES.IMPLEMENTED
    ];
    this.listStatus[CHATSTATUSES.ADJUSTMENT.code] = [
      CHATSTATUSES.ADJUSTMENT,
      CHATSTATUSES.DECISION,
      CHATSTATUSES.IMPLEMENTED
    ];
    this.listStatus[CHATSTATUSES.DECISION.code] = [
      CHATSTATUSES.ADJUSTMENT,
      CHATSTATUSES.DECISION,
      CHATSTATUSES.IMPLEMENTED
    ];
    this.listStatus[CHATSTATUSES.IMPLEMENTED.code] = [
      CHATSTATUSES.ADJUSTMENT,
      CHATSTATUSES.DECISION,
      CHATSTATUSES.IMPLEMENTED
    ];
    this.changeScroll();
  }
  private async _initChat() {
    await this._loadChatHistory(true);
    if (!this.matchId) {
      return;
    }
    await this.updateChatStt(this.matchId);
    await this.initDetailChat(this.matchId, true);
    this.changeScroll();
  }

  ngOnDestroy() {
    // this._initChatChannel();
    if(this.intervalListMsg){
      clearInterval(this.intervalListMsg);
    }
    if(this.intervalListHistoryMsg){
      clearInterval(this.intervalListHistoryMsg);
    }

    // this._unsubscribeChat();
    if (this.currentRoute) {
      this.currentRoute.unsubscribe();
      this.currentRoute = null;
    }
    // this.chatService.disconnect();
  }
  ngAfterContentInit() {
    if(!this.intervalListHistoryMsg){
      this.intervalListHistoryMsg = setInterval(async () => {
        await this._loadChatHistoryNew();
      }, 5000);
    }
  }
  sendMessage(event) {
    if (this.chatContent.trim() == CONSTANT.EMPTY_STRING) {
      return;
    }
    this.sendingStt = true;

    const chatContent = {
      match_id: this.matchId,
      chat_comment: this.chatContent,
    };
    this.dbOperationService
      .insertData(API_URL_GENERATE_CHAT_LIST, chatContent)
      .subscribe((response) => {
        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadSleepEnd(0.3, LOADING_KEY.DATA_INSERT);
        // レスポンス結果を判定
        if (this.commonService.checkInvalidValue(response)) {
          this.messageData.toastMessage(
            new ToastMessageData({
              severity: TOAST.WARN,
              summary: this.commonService.msg(MESSAGE_CODE.E00003),
              detail: this.commonService.msg(MESSAGE_CODE.E00026),
            })
          );
          this.matchId = null;
          this.router.navigate(["pages/platform/c601"]);
        } else if (CONSTANT.EMPTY_STRING !== response.body.Message) {
          if (
            this.listMatchChatDetail.status ==
              CHATSTATUSES.REQUEST_PROPOSAL.code &&
            ((this.listMatchChatDetail.needs_member_id ===
              this.loginUser.member_id && this.listMatchChatDetail.match_type == '0') ||
              (this.listMatchChatDetail.seeds_member_id ===
                this.loginUser.member_id && this.listMatchChatDetail.match_type == '1'))
          ) {
            this.changeStatusProgress(chatContent.match_id);
          }

          // 正常終了の場合
          // this.chatService.sendMessage(this.chatContent);
          this._sendMsgToTop(this.matchId);
          this.chatContent = CONSTANT.EMPTY_STRING;
          this.sendingStt = false;

          setTimeout(() => this._scrollToBottom(), 500);
        } else {
          // 異常終了の場合

          // 異常終了メッセージ
          this.messageData.toastMessage(
            new ToastMessageData({
              severity: TOAST.ERROR,
              summary: this.commonService.msg(MESSAGE_CODE.E00001),
              detail: this.commonService.msg(MESSAGE_CODE.E80002),
            })
          );
        }
      });
  }

  private _sendMsgToTop(matchId) {
    const numberCountUnread = this.listChatCommon.filter(
      (chat) => Number(chat.count_unread) > 0
    ).length;
    this.listChatCommon.map((chat: any, index) => {
      if (matchId == chat.match_id) {
        this.listChatCommon.splice(index, 1);
        this.listChatCommon.splice(numberCountUnread, 0, chat);
        setTimeout(() => this._scrollPanelToTop(), 500);
      }
    });
  }

  private _scrollToBottom() {
    const el = this.chatList.nativeElement;

    el.scrollTop += el.scrollHeight;
    const headerChat = document.getElementById("headerChat");
    headerChat.scrollTop -= headerChat.scrollHeight;
  }

  private _scrollPanelToTop() {
    const el = this.listPanelChat.nativeElement;
    el.scrollTop -= el.scrollHeight;
  }

  /**
   * check login member
   * @param memberId
   * @returns
   */
  isLoginMember(memberId: string) {
    return this.loginUser.member_id === memberId;
  }

  formatTime(timestamp: number) {
    const time = moment(timestamp).format("YYYY/MM/DD HH:mm");
    return time;
  }

  /**
   * handle open to detail chat list
   */
  public async openDetailChat(match_id, countUnread) {
    this.router.navigate(["pages/platform/c601", match_id]);
    if (!match_id || !this.matchId) {
      return;
    }
    this.messageList = [];
    if (countUnread !== "0") {
      await this.updateChatStt(match_id);
    }
    await this.initGetMatchChatDetail(match_id);
    this.changeScroll();
    const headerChat = document.getElementById("headerChat");
    headerChat.scrollTop -= headerChat.scrollHeight;
    await this.initGetListChat(match_id);
    this._initChatChannel(match_id)
  }

  changeScroll() {
    const headerChat = document.getElementById("headerChat");
    const headerChatChild = document.getElementById("headerChatChild");
    if (headerChat && headerChatChild) {
      if (headerChat.offsetHeight >= headerChatChild.offsetHeight) {
        headerChat.classList.remove("scroll")
        headerChat.classList.add("no-scroll")
      } else {
        headerChat.classList.remove("no-scroll")
        headerChat.classList.add("scroll")
      }
    }
  }
  /**
   * init open detail chat
   * @param match_id
   * @returns
   */
  public async initDetailChat(match_id, isLoad) {
    if (!match_id || !this.matchId) {
      return;
    }
    this.messageList = [];

    await this.initGetMatchChatDetail(match_id);
    await this.initGetListChat(match_id, isLoad);
    this.router.navigate(["pages/platform/c601", match_id]);
  }

  /**
   * init get list chat from member
   *
   */
  public initGetListChat(match_id, isload: boolean = true) {
    return new Promise<any>((resolve) => {
      // 画面ロードフラグをON(ロード中状態)
      if (isload) {
        this.loadingState.loadStart(API_URL_GENERATE_CHAT_LIST);
      }
      const table = "chat_detail";
      const matchId = { MatchId: match_id };

      // TODO: load chat list
      this.dbOperationService
        .getForkJoinDataNoTemplate(table, API_URL_GENERATE_CHAT_LIST, matchId)
        .subscribe((response: any) => {
          if (this.commonService.checkInvalidValue(response)) {
            // 警告メッセージ
            this.messageData.toastMessage(
              new ToastMessageData({
                severity: TOAST.WARN,
                summary: this.commonService.msg(MESSAGE_CODE.E00003),
                detail: this.commonService.msg(MESSAGE_CODE.E00026),
              })
            );
          } else if (!this.commonService.checkNoneResponse(response)) {
            // list chat
            this.listChatDetail = response.body.reverse();

            setTimeout(() => this._scrollToBottom(), 0);
          } else {
            this.listChatDetail = new Array();
          }
          // 画面ロードフラグをOFF(ロード終了)
          if (isload) {
            this.loadingState.loadSleepEnd(0.3, API_URL_GENERATE_CHAT_LIST);
          }
        });
      resolve(null);
    });
  }

  /**
   * init get list chat from member
   *
   */
  public initGetListChatNew(match_id) {
    return new Promise<any>((resolve) => {
      const table = "chat_detail";
      const matchId = { MatchId: match_id };

      // TODO: load chat list
      this.dbOperationService
        .getForkJoinDataNoTemplate(table, API_URL_GENERATE_CHAT_LIST, matchId)
        .subscribe((response: any) => {
          if (!this.commonService.checkNoneResponse(response)) {
            // list chat
            this.listChatDetailNew = response.body.reverse();
            if (this.listChatDetail.length < this.listChatDetailNew.length) {
              const numberChatNew =
                this.listChatDetailNew.length - this.listChatDetail.length;
              this.messageList = this.listChatDetailNew.splice(
                this.listChatDetail.length,
                numberChatNew
              );
              this.messageList.map((chat) => {
                if (!this.isLoginMember(chat.chat_member_id_comment)) {
                  this.updateChatStt(chat.chat_match_id, false);
                }
              });
              this.listChatDetail = this.listChatDetail.concat(
                this.messageList
              );
              setTimeout(() => this._scrollToBottom(), 0);
            } else if (
              this.listChatDetail.length > this.listChatDetailNew.length
            ) {
              this.listChatDetail = this.listChatDetailNew;
            } else {
              this.listChatDetailNew.map((chat, index) => {
                if (
                  chat.chat_chat_comment !==
                  this.listChatDetail[index].chat_chat_comment
                ) {
                  this.listChatDetail[index].chat_chat_comment =
                    chat.chat_chat_comment;
                }
              });
            }
          } else {
            this.listChatDetailNew = new Array();
          }
        });
      resolve(null);
    });
  }
  /**
   * init get match chat detail from member
   *
   */
  public initGetMatchChatDetailNew(match_id) {
    return new Promise<any>((resolve) => {
      // TODO: load chat history
      this.dbOperationService
        .getNoTemplateDisplayData(API_URL_MATCH_CHAT_DETAIL, match_id, false)
        .subscribe((response: any) => {
          if (this.commonService.checkInvalidValue(response)) {
            // 警告メッセージ
            this.messageData.toastMessage(
              new ToastMessageData({
                severity: TOAST.WARN,
                summary: this.commonService.msg(MESSAGE_CODE.E00003),
                detail: this.commonService.msg(MESSAGE_CODE.E00026),
              })
            );
            this.matchId = null;
            this.listMatchChatDetail = new Array();
            this.loadingState.loadSleepEnd(0.3, API_URL_MATCH_CHAT_DETAIL);
            this.router.navigate(["pages/platform/c601"]);
            resolve(null);
            return;
          } else if (!this.commonService.checkNoneResponse(response)) {
            // list chat
            this.listMatchChatDetail = response.body;
            this.c601ChatInputForm
              .get("start_implementation_date")
              .setValue(this.listMatchChatDetail?.start_implementation_date);
            this.c601ChatInputForm
              .get("end_implementation_date")
              .setValue(this.listMatchChatDetail?.end_implementation_date);
            this.c601ChatInputForm
                .get("status")
                .setValue(this.listMatchChatDetail?.status);
            this.changeScroll();
          } else {
            this.listMatchChatDetail = new Array();
          }
        });
      resolve(null);
    });
  }
  /**
   * init get match chat detail from member
   *
   */
  public initGetMatchChatDetail(match_id, isload: boolean = true) {
    return new Promise<any>((resolve) => {
      // 画面ロードフラグをON(ロード中状態)
      if (isload) {
        this.loadingState.loadStart(API_URL_MATCH_CHAT_DETAIL);
      }
      // TODO: load chat history
      this.dbOperationService
        .getNoTemplateDisplayData(API_URL_MATCH_CHAT_DETAIL, match_id, false)
        .subscribe((response: any) => {
          if (this.commonService.checkInvalidValue(response)) {
            // 警告メッセージ
            this.messageData.toastMessage(
              new ToastMessageData({
                severity: TOAST.WARN,
                summary: this.commonService.msg(MESSAGE_CODE.E00003),
                detail: this.commonService.msg(MESSAGE_CODE.E00026),
              })
            );
            this.matchId = null;
            this.listMatchChatDetail = new Array();
            this.loadingState.loadSleepEnd(0.3, API_URL_MATCH_CHAT_DETAIL);
            this.router.navigate(["pages/platform/c601"]);
            resolve(null);
            return;
          } else if (!this.commonService.checkNoneResponse(response)) {
            if (
              this.classId == ROLES.TEACHER &&
              response.body.needs_member_id != this.loginUser.member_id
            ) {
              this.loginService.logout();
            }
            if (
              this.classId == ROLES.PROVIDER &&
              response.body.seeds_member_id != this.loginUser.member_id
            ) {
              this.loginService.logout();
            }
            // list chat
            this.listMatchChatDetail = response.body;
            this.c601ChatInputForm
              .get("start_implementation_date")
              .setValue(this.listMatchChatDetail?.start_implementation_date);
            this.c601ChatInputForm
              .get("end_implementation_date")
              .setValue(this.listMatchChatDetail?.end_implementation_date);
            this.c601ChatInputForm
              .get("status")
              .setValue(this.listMatchChatDetail?.status);
            this.changeScroll();
          } else {
            this.listMatchChatDetail = new Array();
          }
          // 画面ロードフラグをOFF(ロード終了)
          if (isload) {
            this.loadingState.loadSleepEnd(0.3, API_URL_MATCH_CHAT_DETAIL);
          }
        });
      this.changeScroll();
      resolve(null);
    });
  }
  public checkNewChat(match_id) {
    if (!match_id) {
      return false;
    }
    return (
      this.listChatCommon
        .map((chatcommon) => chatcommon.match_id)
        .indexOf(match_id) == -1
    );
  }

  /**
   * update status of chat
   */
  public updateChatStt(matchId, isload: boolean = true) {
    return new Promise<any>((resolve) => {
      if (isload) {
        this.loadingState.loadStart(API_URL_CHAT_STATUS);
      }

      // TODO: load chat history
      this.dbOperationService
        .updateData(API_URL_CHAT_STATUS, matchId)
        .subscribe((response: any) => {
          // レスポンス結果を判定
          if (this.commonService.checkInvalidValue(response)) {
            // this.messageData.toastMessage(
            //   new ToastMessageData({
            //     severity: TOAST.WARN,
            //     summary: this.commonService.msg(MESSAGE_CODE.E00003),
            //     detail: this.commonService.msg(MESSAGE_CODE.E00026),
            //   })
            // );
            // this.router.navigate(["pages/platform/c601"])
          } else if (API_RESPONSE.SUCCESS == response.body.Message) {
            // 正常終了の場合
            this.listChatCommon.map((chat, index) => {
              if (chat.match_id == matchId) {
                this.listChatCommon[index].count_unread = "0";
              }
            });
          } else if (this.commonService.checkInvalidValue(response)) {
            this.messageData.toastMessage(
              new ToastMessageData({
                severity: TOAST.WARN,
                summary: this.commonService.msg(MESSAGE_CODE.E00003),
                detail: this.commonService.msg(MESSAGE_CODE.E00026),
              })
            );
            this.matchId = null;
            this.loadingState.loadSleepEnd(0.3, API_URL_CHAT_STATUS);
            this.router.navigate(["pages/platform/c601"]);
            resolve(null);
            return;
          } else {
            // 異常終了の場合

            // 異常終了メッセージ
            this.messageData.toastMessage(
              new ToastMessageData({
                severity: TOAST.ERROR,
                summary: this.commonService.msg(MESSAGE_CODE.E00001),
                detail: this.commonService.msg(MESSAGE_CODE.E80002),
              })
            );
          }
          if (isload) {
            // 画面ロードフラグをOFF(ロード終了)
            this.loadingState.loadSleepEnd(0.3, API_URL_CHAT_STATUS);
          }
          resolve(null);
        });
    });
  }

  public showTimeDialog(): void {
    this.isShowTimeDialog = true;
  }

  public updateCloseDialog(): void {
    this.isShowTimeDialog = false;
  }

  public updateDateSelect(date: any): void {
    if (date == 'error') {
      this.router.navigate(["pages/platform/c601"])
      return;
    }
    this.initGetMatchChatDetail(this.matchId);
    this.c601ChatInputForm
      .get("start_implementation_date")
      .setValue(date?.start_implementation_date);
    this.c601ChatInputForm
      .get("end_implementation_date")
      .setValue(date?.end_implementation_date);
  }

  public formatDateJapan(dateTime: any): string {
    if (dateTime) {
      const date = new Date(dateTime);
      const lang = "en",
        year = date.toLocaleString(lang, { year: "numeric" }),
        month = date.toLocaleString(lang, { month: "numeric" }),
        day = date.toLocaleString(lang, { day: "numeric" });
      return `${year}年${month}月${day}日`;
    }
    return ``;
  }

  //change stt of firts message
  private changeStatusProgress(matchId:number) {
    const statusProgress = "1";
    this.dbOperationService
      .updateData(API_URL_UPDATE_MATCH_STATUS, matchId, {
        status: statusProgress,
      })
      .subscribe((response) => {
        // APIレスポンスの判定
        if (!this.commonService.checkRunningNormallyResponse(response)) {
          // APIが正常終了の場合
          this.messageService.add({
            severity: TOAST.ERROR,
            summary: this.commonService.msg(MESSAGE_CODE.E00002),
            detail: this.commonService.msg(MESSAGE_CODE.E00006),
          });
        } else {
          this.initGetMatchChatDetail(matchId);
        }
        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadEnd(LOADING_KEY.DATA_UPDATE);

        // 親画面に更新対象IDを設定
      });
  }

  cancelConfirm(event) {
    this.c601ChatInputForm.get("status").setValue(this.listMatchChatDetail?.status);
  }
  public changeStatus(event) {
    this.c601ChatInputForm.get("status").setValue(this.listMatchChatDetail?.status);
    this.confirmRejectMatchDialogComponent.updateMatchStatus(
      API_URL_UPDATE_MATCH_STATUS,
      this.matchId,
      event.value
    );
  }

  redirectListChat(event) {
    this.initGetMatchChatDetail(this.matchId);
  }

  public setClassStatus(matchStatus: any) {
    let classActiveColor = CONSTANT.EMPTY_STRING;
    switch (matchStatus) {
      case CHATSTATUSES.REQUEST_PROPOSAL.code:
        classActiveColor = "status-0-color";
        break;
      case CHATSTATUSES.ADJUSTMENT.code:
        classActiveColor = "status-1-color";
        break;
      case CHATSTATUSES.DECISION.code:
        classActiveColor = "status-2-color";
        break;
      case CHATSTATUSES.IMPLEMENTED.code:
        classActiveColor = "status-3-color";
        break;
      case CHATSTATUSES.END.code:
        classActiveColor = "status-4-color";
        break;
      default:
        classActiveColor = "status-color";
        break;
    }

    return classActiveColor;
  }

  public setClassStatusDropdown(matchStatus) {
    let classActiveColor = CONSTANT.EMPTY_STRING;
    switch (matchStatus) {
      case CHATSTATUSES.REQUEST_PROPOSAL.code:
        classActiveColor = "color-status-0";
        break;
      case CHATSTATUSES.ADJUSTMENT.code:
        classActiveColor = "color-status-1";
        break;
      case CHATSTATUSES.DECISION.code:
        classActiveColor = "color-status-2";
        break;
      case CHATSTATUSES.IMPLEMENTED.code:
        classActiveColor = "color-status-3";
        break;
      case CHATSTATUSES.END.code:
        classActiveColor = "color-status-4";
        break;
      default:
        break;
    }
    return classActiveColor;
  }
  public setClassBgColor(message) {
    let classBackground = CONSTANT.EMPTY_STRING;
    let positionMsg = CONSTANT.EMPTY_STRING;
    if (this.isLoginMember(message.chat_member_id_comment)) {
      positionMsg = "me";
      classBackground = "me-bg-chat";
    } else {
      classBackground = "other-bg-chat";
    }
    return [classBackground, positionMsg];
  }

  public setClassBgColorSocket(memberId, classId) {
    let classBackground = CONSTANT.EMPTY_STRING;
    let positionMsg = CONSTANT.EMPTY_STRING;
    if (this.isLoginMember(memberId)) {
      positionMsg = "me";
      classBackground = "me-bg-chat";
    } else {
      classBackground = "other-bg-chat";
    }
    return [classBackground, positionMsg];
  }

  number(value: string) {
    return Number(value);
  }
  //update message
  updateMsg(i: number, message: any) {
    this.numMsgEdit = i;
    this.chatContentEdit = message.chat_chat_comment;
  }

  //cancel Edit mode message
  cancelEdit() {
    this.confirmRejectMatchDialogComponent.cancelEditMsg();
  }

  CancelEditMode(numMsg: number) {
    this.numMsgEdit = numMsg;
  }

  editMessage(message: any, index: number, type: string) {
    if (this.chatContentEdit.trim() == CONSTANT.EMPTY_STRING) {
      this.messageData.toastMessage(
        new ToastMessageData({
          severity: TOAST.ERROR,
          summary: this.commonService.msg(MESSAGE_CODE.E00034),
        })
      );
      return;
    }
    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart(API_URL_GENERATE_CHAT_LIST);
    const chatContent = { chat_comment: this.chatContentEdit };
    this.dbOperationService
      .updateData(API_URL_GENERATE_CHAT_LIST, message.chat_chat_id, chatContent)
      .subscribe((response) => {
        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadSleepEnd(0.3, API_URL_GENERATE_CHAT_LIST);
        // レスポンス結果を判定
        if (this.commonService.checkInvalidValue(response)) {
          this.messageData.toastMessage(
            new ToastMessageData({
              severity: TOAST.WARN,
              summary: this.commonService.msg(MESSAGE_CODE.E00003),
              detail: this.commonService.msg(MESSAGE_CODE.E00026),
            })
          );
          this.router.navigate(["pages/platform/c601"]);
        } else if (CONSTANT.EMPTY_STRING !== response.body.Message) {
          if (type === "DB") {
            this.listChatDetail[index].chat_chat_comment = this.chatContentEdit;
          }
          // 異常終了メッセージ
          this.messageData.toastMessage(
            new ToastMessageData({
              severity: TOAST.SUCCESS,
              summary: this.commonService.msg(MESSAGE_CODE.T00002),
            })
          );
          this.numMsgEdit = null;
        } else {
          // 異常終了メッセージ
          this.messageData.toastMessage(
            new ToastMessageData({
              severity: TOAST.ERROR,
              summary: this.commonService.msg(MESSAGE_CODE.E00001),
              detail: this.commonService.msg(MESSAGE_CODE.E80002),
            })
          );
        }
      });
  }

  // delete message
  deleteMsg(index: number, message: any) {
    this.confirmRejectMatchDialogComponent.deleteMessage(
      index,
      message.chat_chat_id,
      API_URL_GENERATE_CHAT_LIST
    );
  }

  handleDeleteFromList(index) {
    this.listChatDetail.splice(index, 1);
  }

  reset() {
    this.matchId = null;
    this.router.navigate(["pages/platform/c601"], {});
  }
  changeStatusFail(){
    this.messageData.toastMessage(
      new ToastMessageData({
        severity: TOAST.ERROR,
        summary: this.commonService.msg(MESSAGE_CODE.E00001),
        detail: this.commonService.msg(MESSAGE_CODE.E80002),
      })
    );
  }
  public setClassesKind(recruitment_kind: any) {
    if (recruitment_kind === RECRUITMENT_TYPE.CONFERENCE) {
      return 'color-conference';
    } else if (recruitment_kind === RECRUITMENT_TYPE.OTHERS) {
      return 'color-others';
    } else if (recruitment_kind === RECRUITMENT_TYPE.TYPE_3) {
      return "color-recruitment-kind3";
    } else {
      return 'no-category';
    }
  }
  
  public moveC331(agenda_id?: any): void {
    this.router.navigate([`pages/platform/c331/${agenda_id}`]);
  }
  
  public moveC341(agenda_id?: any): void {
    this.router.navigate([`pages/platform/c341/${this.matchId}/${agenda_id}`]);
  }
    
  public moveC404(agenda_id?: any): void {
    this.router.navigate([`pages/platform/c404/${agenda_id}`]);
  }

  showPulldown() {
    if (['0', '1','2','3'].includes(this.listMatchChatDetail?.status)) {
      if (this.listMatchChatDetail?.match_type == '0') {
        if (this.classId == '2') {
          if (this.isLoginMember(this.listMatchChatDetail?.needs_member_id)) {
            return true
          }
        }
      } else if (this.listMatchChatDetail?.match_type == '1') {
        if (this.classId == '1') {
          if (this.isLoginMember(this.listMatchChatDetail?.seeds_member_id)) {
            return true
          }
        }
      }
    }
    return false
  }

  getUrl(isAdmin?: any) {
    if (isAdmin) {
      return 'https://form.sumasapo.metro.tokyo.lg.jp/user_enq?code=' + this.listMatchChatDetail?.seeds_sumasapo_no + '&mid=' + this.listMatchChatDetail?.match_id
    }
    if (this.classId == '1') {
      return 'https://form.sumasapo.metro.tokyo.lg.jp/suppoter_enq?code=' + this.listMatchChatDetail?.seeds_sumasapo_no + '&mid=' + this.listMatchChatDetail?.match_id
    } else if (this.classId == '2') {
      return 'https://form.sumasapo.metro.tokyo.lg.jp/organizer_enq?code=' + this.listMatchChatDetail?.needs_sumasapo_no + '&mid=' + this.listMatchChatDetail?.match_id
    }
  }

  resetChat(){
    this.chatContent = ''
  }
}